<template>
  <!-- 公共数据源-服务项目 -->
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <!-- tab -->
      <a-tabs
        :default-active-key="tabIndex"
        :animated="false"
        @change="callback"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
          <div v-if="tabIndex == 0">
            <div class="main-content-header">
              <div class="item">
                <div class="title">
                  <span class="title-1">服务类型管理</span>
                  <a-icon type="info-circle" />
                  <span class="sub-title"
                    >仅限技术设定，如需新增服务类型，请联系系统管理员。</span
                  >
                </div>
              </div>
              <a-button type="primary" @click="onAddSpecification1">
                添加分类
              </a-button>
            </div>
            <my-table
              :columns="columns"
              :data-source="dataSource"
              :pagination="false"
              :loading="loading"
              :row-key="(record) => record.type"
            >
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="jump(record)">
                  {{ record.name }}
                </a-button>
                <!-- <a-button type="link">
            禁用
          </a-button> -->
              </template>
            </my-table>

            <div class="main-content-header">
              <div class="item">
                <div class="title">
                  <span class="title-1">规格管理</span>
                  <a-icon type="info-circle" />
                  <span class="sub-title">仅用于家电、商品等规格显示</span>
                </div>
              </div>
              <a-button type="primary" @click="onAddSpecification">
                添加
              </a-button>
            </div>

            <my-table
              :columns="columns2"
              :data-source="dataSource2"
              :pagination="false"
              :loading="loading2"
              :row-key="(record) => record.id"
            >
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="onChangeSpecification(record)">
                  编辑
                </a-button>
              </template>
            </my-table>
          </div>
          <div v-else>
            <div class="main-content-header">
              <div class="item">
                <div class="title">
                  <span class="title-1">服务类型管理</span>
                  <a-icon type="info-circle" />
                  <span class="sub-title"
                    >仅限技术设定，如需新增服务类型，请联系系统管理员。</span
                  >
                </div>
              </div>
              <a-button type="primary" @click="onAddSpecification1">
                添加分类
              </a-button>
            </div>
            <my-table
              :columns="columns"
              :data-source="dataSource"
              :pagination="false"
              :loading="loading"
              :row-key="(record) => record.type"
            >
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="jumpAreaid(record)">
                  {{ record.name }}
                </a-button>
                <!-- <a-button type="link">
            禁用
          </a-button> -->
              </template>
            </my-table>

            <div class="main-content-header">
              <div class="item">
                <div class="title">
                  <span class="title-1">规格管理</span>
                  <a-icon type="info-circle" />
                  <span class="sub-title">仅用于家电、商品等规格显示</span>
                </div>
              </div>
              <a-button type="primary" @click="onAddSpecification">
                添加
              </a-button>
            </div>

            <my-table
              :columns="columns2"
              :data-source="dataSource2"
              :pagination="false"
              :loading="loading2"
              :row-key="(record) => record.id"
            >
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="onChangeSpecification(record)">
                  编辑
                </a-button>
              </template>
            </my-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <!-- 添加分类 -->
    <drawer :title="drawerTitle" :width="500" :visible="visible1">
      <a-form-model
        ref="ruleForm"
        :model="formClass"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item ref="name" label="名称" prop="name">
          <a-input
            placeholder="请输入名称"
            v-model="formClass.name"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="typeField" label="服务类型" prop="typeField">
          <a-input
            placeholder="请输入服务类型"
            v-model="formClass.typeField"
            @blur="
              () => {
                $refs.typeField.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="status" label="开启状态" prop="status">
          <a-select
            class="select"
            :default-value="0"
            @change="handleSelectChange"
          >
            <a-select-option :value="0"> 关闭 </a-select-option>
            <a-select-option :value="1"> 开启 </a-select-option>
          </a-select>
        </a-form-model-item>
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose"> 取消 </a-button>
          <a-button type="primary" @click="handleDrawerClass"> 提交 </a-button>
        </div>
      </a-form-model>
    </drawer>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item
          ref="name"
          label="名称"
          prop="name"
          v-if="form.type === undefined"
        >
          <a-input
            placeholder="请输入名称"
            v-model="form.name"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <div class="drawer-btn-wrap">
          <a-button @click="handleDrawerClose"> 取消 </a-button>
          <a-button type="primary" @click="handleDrawerSubmit"> 提交 </a-button>
        </div>
      </a-form-model>
    </drawer>
  </div>
</template>

<script>
import drawer from '../../components/drawer/index'
export default {
  data() {
    return {
      title: '',
      dataSource: [],
      tabIndex: 0,
      tabs: [
        {
          key: 0,
          name: '公共数据',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        {
          key: 1,
          name: '省市区选择',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        }
      ],
      columns: [
        {
          title: '序号',
          // width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '服务类型',
          dataIndex: 'name'
        },
        {
          title: '操作',
          fixed: 'right',
          // width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      types: {
        Serving: '家电',
        Goods: '商品',
        Envigover: '环境治理',
        card: '汽车美容',
        IndoorAir: '室内消毒',
        killmites: '除螨虫',
        Convenient: '便民服务',
        Otherinstall: '其它安装',
        Homeinstall: '家具安装',
        Hotshare: '热门推荐',
        Recycling: '资源回收',
        Otherrepair: '其它维修',
        Furniture: '家具保新'

      },
      dataSource2: [],
      columns2: [
        {
          title: '序号',
          // width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '创建时间',
          dataIndex: 'createAt'
        },
        {
          title: '操作',
          fixed: 'right',
          // width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading2: true,
      visible: false,
      drawerTitle: '',
      formClass: {
        name: '',
        typeField: '',
        status: 0
      },
      form: {
        name: ''
      },
      visible1: false,
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        typeField: [
          { required: true, message: '请输入服务类型', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    drawer
  },
  created() {
    this.title = this.$getPageTitle()
    this.getPunlicService()
    this.getSpecification()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (this.tabIndex == 0) {
        this.getPunlicService()
        this.getSpecification()
      }
    },
    getPunlicService() {
      // 获取公共数据源服务类型
      const data = {
        page: 1,
        limit: 20
      }
      this.$axios.getPunlicService(data).then((res) => {
        this.dataSource = res.data.data.list
        this.loading = false
      })
    },
    jump(e) {
      // 跳转
      // const types = {
      //   Serving: '/publicServiceItems',
      //   Goods: '/publicGoods',
      //   Envigover: '/publicSurroundings',
      //   Envigover: '/publicServiceItems',
      //   card: '/publicServiceItems',
      //   IndoorAir: '/publicServiceItems',
      //   Killmites: '/publicServiceItems',
      //   Furniture: '/publicServiceItems'
      // }
      // console.log('11111111', e, types)
      let path = ''
      if (e.orderType === 'normal') {
        path = 'publicServiceItems'
      }
      if (e.orderType === 'card') {
        path = 'publicServiceItems'
      }
      if (e.orderType === 'Goods') {
        path = 'publicGoods'
      }
      if (e.orderType === 'recycling') {
        // path = 'recycling'
        path = 'publicServiceItems'
      }
      if (e.orderType === 'beforehand') {
        // path = 'recycling'
        path = 'publicServiceItems'
      }
      if (path) {
        this.$router.push({
          path: path,
          query: {
            id: e.id,
            name: e.name
          }
        })
      }
    },
    jumpAreaid(e) {
      let path = ''
      if (e.orderType === 'normal') {
        path = 'publicAreaId'
      }
      if (e.orderType === 'card') {
        path = 'publicAreaId'
      }
      if (e.orderType === 'Goods') {
        path = 'publicAreaId'
      }
      if (e.orderType === 'recycling') {
        // path = 'recycling'
        path = 'publicAreaId'
      }
      if (e.orderType === 'beforehand') {
        // path = 'recycling'
        path = 'publicAreaId'
      }
      if (path) {
        this.$router.push({
          path: path,
          query: {
            id: e.id,
            name: e.name
          }
        })
      }
    },
    // 开启状态
    handleSelectChange(e) {
      this.formClass.status = e
    },

    getSpecification() {
      // 获取规格列表
      this.$axios.getSpecification().then((res) => {
        this.dataSource2 = res.data.data
        this.loading2 = false
      })
    },
    onAddSpecification() {
      // 添加规格
      this.drawerTitle = '添加规格'
      this.visible = true
    },
    onAddSpecification1() {
      // 添加规格
      this.drawerTitle = '添加分类'
      this.visible1 = true
    },
    onChangeSpecification(e) {
      // 编辑规格列表
      this.drawerTitle = '修改规格'
      this.form.id = e.id
      this.form.name = e.name
      this.visible = true
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      this.visible1 = false
      this.drawerTitle = ''
      if (this.form.id) {
        delete this.form.id
      }
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerClass() {
      // 提交分类
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.categoryInsert()
        }
      })
    },
    handleDrawerSubmit() {
      // 提交添加规格
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.changeSpecification()
          } else {
            this.addSpecification()
          }
        }
      })
    },
    // 添加分类
    categoryInsert() {
      console.log('this.formClass', this.formClass)
      this.$axios.categoryInsert(this.formClass).then(() => {
        this.$message.success('操作成功')
        this.visible1 = false
        this.getPunlicService()
      })
    },
    addSpecification() {
      // 添加规格
      this.$axios.addSpecification(this.form).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getSpecification()
      })
    },
    changeSpecification() {
      // 编辑规格
      this.$axios.changeSpecification(this.form).then(() => {
        this.$message.success('操作成功')
        this.handleDrawerClose()
        this.getSpecification()
      })
    }
  }
}
</script>
<style scoped>
.title .anticon {
  font-size: 16px;
  margin: 0 4px 0 16px;
  color: rgba(0, 0, 0, 0.65);
}

.sub-title {
  font-size: 14px;
  line-height: 16px;
  color: #595959;
}
</style>
